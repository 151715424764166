.hero-banner {
  position: relative;
  height: 100vh;
  width: 100vw;

  .top-banner {
    border: 1px solid #a4485d;
    margin: 50px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;

    .logo {
      font-family: forma-djr-banner, sans-serif;
      font-weight: 700;
      font-size: 3em;
      letter-spacing: 1px;
      color: #a4485d;
      padding: 20px;
    }

    .navigation-icons {
      font-size: 1.1em;
      padding: 20px;

      a {
        padding: 0 20px;
        color: #a4485d;
        transition: 0.3s;
      }

      a:hover {
        color: #ff7f7f;
      }
    }
  }

  @media screen and (max-width: 500px) {
    .top-banner {
      flex-direction: column;
      justify-content: center;
      padding: 20px 0;

      .logo {
        padding: 10px 0;
      }

      .navigation-icons {
        line-height: 0px;
        padding: 0;
      }
    }
  }
}
