body {
  background-color: #24132b;
  overflow-x: hidden;
}

.inview-split-parent {
  overflow: hidden;
}

.inview-split-child {
  display: inline-block;
}

.fullscreen-loader {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: #ffb3b3;
  z-index: 1000;
}

.loader-potato {
  height: 100px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  mix-blend-mode: color-burn;
}

.main-layout {
  height: 100vh;
  width: 100vw;
}

.is-loading {
  overflow: hidden;
}
