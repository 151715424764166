.team-work {
  padding: 15vh 10vw;
  font-size: 1.5em;
  letter-spacing: 2px;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 100;
  font-style: normal;
  background-color: #24132b;
  color: #bdaec5;
  z-index: 100;
  display: flex;

  .team-work-content {
    width: 100%;
    padding: 0 10% 100px 10%;
    border-bottom: 1px #bdaec5 solid;

    .team-work-title {
      text-align: center;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-bottom: 0;
      
      .team-work-title-text {
        font-weight: 600;
        font-size: 4em;  
      }

      .team-work-title-link {
        font-size: 0.8em;
        font-weight: 400;

        a {
          transition: 0.3s;
          color: #bdaec5;
        }
    
        a:hover {
          transition: 0.3s;
          color: #ff8180;
        }
      }
    }
  }

  .team-work-title-body {
    display: flex;
    justify-content: space-around;
    gap: 0;
  }

  .team-work-repos {
    margin: 150px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    z-index: 1000;
    flex-basis: 50%;

    .team-work-repo {
      text-decoration: none;
      color: #bdaec5;
      text-align: left;
      width: 100%;
      flex-basis: 100%;
      padding: 30px 0 0 0;

      .team-work-repo-title {
        font-size: 2em;
        font-weight: 600;
        text-transform: capitalize;
        padding-bottom: 5px;
      }

      .team-work-repo-title {
        display: inline-block;
        position: relative;
        transition: 0.5s;
      }

      @media screen and (max-width: 800px) {
        .team-work-repo-title {
          font-size: 1em;
        }
      }

      .team-work-repo-title:hover {
        text-decoration: none;
        color: #ff8180;
        // transform: rotate(5deg);
      }

      .team-work-repo-title:after {
        content: '';
        position: absolute;
        display: block;
        width: 0%;
        height: 20px;
        margin-top: -0.8em;
        background: #ff8180;
        transform-origin: center left;
        transition: 0.25s ease;
        left: -0.3em;
      }

      .team-work-repo-title:hover:after {
        width: 110%;
      }

      .team-work-repo-description,
      .team-work-repo-updated {
        font-size: 0.6em;
        font-weight: 400;
      }

      .team-work-repo-updated {
        color: #8a808f;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .team-work .team-work-content {
    flex-direction: column;
    padding: 0;

    .team-work-repos {
      justify-content: center;
      flex-basis: 100%;
      margin-bottom: 100px;

      .team-work-repo {
        text-align: center;
        max-width: 100%;

        .team-work-repo-title {
          font-size: 0.9em;
        }

        .team-work-repo-description,
        .team-work-repo-updated {
          font-size: 0.5em;
        }
      }
    }

    .team-work-title {
      font-size: 2em;
      padding-bottom: 50px;
      
      .team-work-title-text {
        font-size: 0.8em;
      }

      .team-work-title-link {
        font-size: 0.3em;
      }
    }

    .team-work-title-body {
      padding-bottom: 0;

      .team-work-repos {
        margin-top: 0;
      }
    }

    .upper-paragraph {
      font-size: 0.8em;
    }

    .team-work-image {
      display: none;
    }
  }
}

@media screen and (max-width: 800px) {
  .team-work .team-work-content .upper-paragraph {
    padding-left: 0;
    width: 90%;
  }

}
