.mountains {
  position: absolute;
  height: 100%;
  width: 100vw;
  min-width: 1000px;
  top: 0;
  left: -5px;
  opacity: 1;
  z-index: -20;
}

.mountains-2 {
  position: absolute;
  height: 100%;
  width: 100vw;
  min-width: 1000px;
  top: 0;
  left: min(-100vw, -1000px);
  opacity: 1;
  z-index: -20;
}

.mountains-layer-1 {
  top: 30vh;
}

.mountains-layer-2 {
  z-index: -30;
}

.mountains-layer-3 {
  z-index: -40;
}

.mountains-layer-4 {
  z-index: -50;
}

.mountains-layer-5 {
  z-index: -60;
}

@media screen and (max-height: 1400px) and (max-width: 1100px) {
  .mountains-layer-1 {
    top: 20vh;
  }
}

@media screen and (max-height: 1200px) {
  .mountains,
  .mountains-2 {
    top: 10vh;
  }

  .mountains-layer-1 {
    top: 30vh;
  }
}

@media screen and (max-height: 1200px) and (max-width: 1600px) {
  .mountains,
  .mountains-2 {
    height: 120%;
    top: 10vh;
  }

  .mountains-layer-1 {
    top: 30vh;
  }
}

@media screen and (max-width: 800px) {
  .mountains,
  .mountains-2 {
    top: 40vh;
  }
}

@media screen and (max-width: 600px) {
  .mountains,
  .mountains-2 {
    top: 20vh;
    height: 100%;
  }

  .mountains-layer-1 {
    top: 40vh;
  }
}
