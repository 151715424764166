.team-descriptor {
  padding: 15vh 10vw 0 10vw;
  font-size: 1.5em;
  letter-spacing: 2px;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 100;
  font-style: normal;
  background-color: #24132b;
  color: #bdaec5;
  z-index: 100;

  .team-descriptor-content {
    width: 100%;
    padding-bottom: 200px;
    border-bottom: 1px #bdaec5 solid;

    .team-descriptor-title {
      font-weight: 600;
      font-size: 4em;
      line-height: 0.8em;
      display: flex;
      justify-content: space-around;
      width: 100%;
      padding-bottom: 150px;
    }
  }

  .team-descriptor-portraits {
    display: flex;
    // justify-content: space-between;
    // flex-direction: column;
    flex-wrap: wrap;
    gap: 50px;
    width: 100%;

    .team-descriptor-portrait {
      flex: 21% 1;
      height: 500px;
      background-color: #bdaec5;
      min-width: 300px;
    }
  }
}

@media screen and (max-width: 1400px) {
  .team-descriptor .team-descriptor-content {
    flex-direction: column;

    .team-descriptor-title {
      font-size: 2em;
      padding-bottom: 50px;
    }

    .upper-paragraph {
      font-size: 0.8em;
    }
  }
}

@media screen and (max-width: 800px) {
  .team-descriptor .team-descriptor-content .upper-paragraph {
    padding-left: 0;
    width: 90%;
  }
}
