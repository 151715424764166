.hero-background {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: -10%;
  z-index: -100;
  background-color: #ffcaca;
}

.hero-main-image {
  position: absolute;
  height: 30%;
  max-height: 500px;
  left: 50%;
  top: 200px;
  z-index: -2;
}

.cloud-1 {
  position: absolute;
  height: 50%;
  top: 100px;
  left: -150vw;
  z-index: -5;
}

.cloud-2 {
  position: absolute;
  height: 50%;
  top: 200px;
  left: -150vw;
  z-index: 10;
}

.footer-base {
  width: 100vw;
  height: 1200px;
  background-color: #24132b;
  position: absolute;
  left: 0;
  top: 100vh;
  z-index: -21;
}

.banner-text {
  position: absolute;
  width: 100%;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 10em;
  letter-spacing: 2px;
  line-height: 0.8em;
  display: flex;
  justify-content: space-around;
  top: 30vh;
  z-index: -30;
  opacity: 0.95;

  .banner-text-body {
    color: #fff7f7;
  }
}

@media screen and (max-height: 1400px) and (max-width: 1100px) {
  .footer-base {
    top: calc(90vh);
  }
}

@media screen and (max-height: 1200px) {
  .footer-base {
    top: 95vh;
  }
}

@media screen and (max-width: 500px) {
  .banner-text {
    font-size: 5em;
    letter-spacing: 0.1px;
    justify-content: baseline;

    .banner-text-body .supporting-text {
      font-size: 0.5em;
      letter-spacing: 0.5px;
      line-height: 1em;
    }
  }

  .hero-main-image {
    height: 50vw;
  }

  .cloud-1,
  .cloud-2 {
    display: none;
  }
}

@media screen and (max-height: 1100px) {
  .cloud-1,
  .cloud-2 {
    z-index: -10;
  }
}

@media screen and (max-width: 800px) {
  .footer-base {
    top: 100vh;
  }
}
