.team-descriptor-portrait {
  flex: 1 1 auto;
  min-width: 100px;

  .team-descriptor-image {
    height: 300px;
    background-color: #bdaec5;
    margin-bottom: 20px;
    overflow: hidden;
    background-size: 100% auto;
    filter: hue-rotate(220deg);
  }

  .team-descriptor-portrait-text {
    font-size: 0.6em;
    font-weight: 400;

    .team-descriptor-name {
      font-weight: 600;
      text-transform: uppercase;
    }

    .team-descriptor-title {
      padding-bottom: 10px;
    }

    .team-descriptor-connect a {
      text-decoration: none;
      border-bottom: 1px solid #8c7b96;
      color: #8c7b96;
      transition: 0.3s;
    }

    .team-descriptor-connect a:hover {
      transition: 0.3s;
      color: #c2b3ca;
      border-bottom: 1px solid #c2b3ca;
    }

    .team-descriptor-linkedin {
      margin-right: 20px;
    }
  }
}
