.team-descriptor {
  padding: 0 10vw;
  font-size: 1.5em;
  letter-spacing: 1px;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: #24132b;
  color: #bdaec5;
  z-index: 100;

  .team-descriptor-content {
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding-bottom: 200px;
    border-bottom: 1px #bdaec5 solid;

    .team-descriptor-title {
      font-weight: 600;
      font-size: 4em;
      line-height: 0.8em;
    }

    .upper-paragraph {
      width: 500px;
      color: #bdaec5;
      display: inline-block;
      font-size: 0.8em;

      .strong {
        color: #ff8180;
        z-index: 1000;
        font-weight: 600;
        letter-spacing: 1px;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .team-descriptor .team-descriptor-content {
    flex-direction: column;

    .team-descriptor-title {
      font-size: 2em;
      padding-bottom: 50px;
    }

    .upper-paragraph {
      font-size: 0.8em;
    }
  }
}

@media screen and (max-width: 800px) {
  .team-descriptor .team-descriptor-content .upper-paragraph {
    padding-left: 0;
    width: 90%;
  }
}
